.footerSection {
    background-color: #000;
    color: #808080;
    z-index: 1;

    ul {
        display: flex;
        margin: auto;

        li {
            padding: 0;
            a {
                color: #808080;
                transition: .5s;

                div {
                    min-width: auto;
                    white-space: nowrap;

                    svg {
                        color: #808080;
                        font-size: 2rem;
                        transition: .5s;
                    }
                }

                &:hover {
                    color: #fff;

                    svg {
                        color: #fff;
                    }

                }
            }
        }
    }
}

.floatingSocialIcon {
    a {
        &[aria-label="Facebook"]:hover {
            color: #1877F2;
            background-color: #fff;
        }
        &[aria-label="Twitter"]:hover {
            color: #1DA1F2;
            background-color: #fff;
        }
        &[aria-label="Instagram"]:hover {
            color: #E4405F;
            background-color: #fff;
        }
        &[aria-label="Youtube"]:hover {
            color: #FF0000;
            background-color: #fff;
        }
    }
}