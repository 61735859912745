.partnerSlider {
    .slick-slide {
        opacity: .6;
        &.slick-center {
            opacity: 1;
        }
        div {
            margin: 0 25px;
            text-align: center;
            img {
                height: 100px;
                width: auto!important;
                max-width: 100%;
            }
        }
    }
}

.slick-slider {
    .slick-dots {
        bottom: 25px;
    }
}