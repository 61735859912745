.homebanner {
  position: relative;

  .slick-slider {
    height: calc(100vh - 64px);
    overflow: hidden;

    .slick-slide {
      position: relative;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25);
        // z-index: -1;
      }
    }
  }

  .bannerContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.statisticSections {
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  &.statisticSectionsBgColor {
    &:before {
      background: #17939b;
    }

    h2 {
      color: #00525a;
      text-shadow: 1px 2px 0px rgba(255, 255, 255, 0.6);
    }

    h5 {
      color: #fff;
    }
  }
}

.awardCarousel {
  .slick-slider {
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-slide {
      position: relative;
      z-index: 0;

      div {
        margin: 0 25px;

        img {
          width: auto !important;
          max-width: 100%;
        }
      }
    }
  }
}

.ourProgrammes {
  padding: 24px;
  text-decoration: none;
  transition: 300ms;
  display: block;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    &:before {
      width: 240px;
      height: 240px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -120px;
    left: -120px;
    transform: translate(0);
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    transition: 300ms;
  }
}

.statisticSections {
  .Count {
    min-width: 215px;
  }
}
