.MuiAppBar-root.MuiAppBar-colorDefault {
    background-color: #fff;
}
.MuiAvatar-root .MuiAvatar-img {
    width: auto;
}
.navMenu {
    // display: flex;
    
    li {
        position: relative;
        width: auto;
        padding: 0;
        display: block;
        a {
            font-size: .875rem;
            font-weight: 500;
            svg {
                font-size: 1rem;
            }
            &.active {
                color: #17939b;
            }
        }
        &:hover {
            .subNavMenu {
                visibility: visible;
                opacity: 1;
                height: auto;
            }
        }
        &.active {
            @media (max-width: 1200px) {
                .subNavMenu {
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                }
            }
        }
        .subNavMenu {
            // position: absolute;
            top: 100%;
            background: #fff;
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 4px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: .5s;
            margin: 0;
            padding: 0;
            li {
                a {
                    white-space: nowrap;
                    &.active {
                        color: #00525a;
                        background-color: #17939b;
                    }
                }
            }
        }
    }
}
