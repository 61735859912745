.infoGraphicList {
    @media (min-width: 900px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 600px;
        margin: auto!important;
    }

    li {
        width: 200px;
        padding: 0;
        align-items: center;
        justify-content: center;

        .hexagonContent {
            max-width: 100px;
            padding: 15px 0px;
            height: 150px;
            text-align: center;
            background: #c9fafe;
            position: relative;
            margin-bottom: 0px;
            color: #00434a;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 100%;
                border: 75px solid transparent;
                border-right: 48px solid #c9fafe;
            }

            &::after {
                right: auto;
                left: 100%;
                border-left: 48px solid #c9fafe;
                border-right-color: transparent;
            }

            p {
                font-size: .75rem;
                color: #17939b;
                span {
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }

    }
}